function postForm(){
  $('#main, #search-results, #search-results a, #searchwords, #dosearch').css('cursor','wait');
  
	var form = $('form.search');
	var loadpresets = $('input[name="loadpresets"]').val();
	if(loadpresets == 'Y'){
	
  	if(request){
  		request.abort();
  	}
  	
      request = $.post($(form).attr('action'),form.serialize(),function(data){
        $('#search-results').html(data);
  	  processHiddenInputs();
  	  processHiddenInputsProgType();
  	  generateCookie();
      $('#main, #search-results, #search-results a, #searchwords, #dosearch').css('cursor','');
      });
	
	}else{
		$(form).submit();
	}
}

function processHiddenInputs(){
	$('input[type="hidden"]:not([value=""])').each(function(){
		$('a[data-id="'+$(this).val()+'"][data-param="'+$(this).attr('name')+'"]').addClass('selected').closest('div').addClass("enabled");
	});
}
function processHiddenInputsProgType(){
  $('a[data-param="p_prog_type"]').show();
	$('input[type="hidden"][name="p_prog_type"]:not([value=""])').each(function(){
	  if ($(this).val() != '') {
		  $('a[data-param="p_prog_type"]').hide();
		  $('a[data-id="'+$(this).val()+'"][data-param="p_prog_type"]').show();
	  }
	});
}
function checkOrUncheck(el){
	if($(el).hasClass('selected')){
		$(el).toggleClass("selected");
		$('input[name="'+$(el).attr('data-param')+'"]').removeAttr("value");
	}else{
		$('input[name="'+$(el).attr('data-param')+'"]').attr("value",$(el).attr("data-id"));
		$(el).toggleClass('selected');
	}

}

function resetInputs(all,reset){
	if(all){
		$('input[type="hidden"]:not(.default)').val('');
	}
	if(reset){
		$('input[name="p_searchwords"]').val('');
	}
	$('input[name="p_page"]').val(1);
}

function generateCookie(){
	var cookie_name = $('input[name="cookie"]').val();
	$.cookie(cookie_name,'',{ path:'/'});
	$('form input:not(.skip-cookie)').each(function(){
		if($(this).attr('type') == 'hidden' || $(this).attr('type') == 'search'){
			appendCookie(cookie_name,$(this).attr('name')+':'+$(this).val(),'/');
		}
	});
	splitCookie();
}
function appendCookie(cookie,value,path){
	var new_value = appendValue($.cookie(cookie),value);
	if(path){
	   $.cookie(cookie,new_value,{ path: path });
	}else{
	   $.cookie(cookie,new_value);
	}
}
function eraseCookie(path){
	var cookie_name = $('input[name="cookie"]').val();
	if(path){
	   $.cookie(cookie_name,'',{ path: path });
	}else{
	   $.cookie(cookie_name,'');
	}
}
function splitCookie(){
	var cookie_name = $('input[name="cookie"]').val();
	try	{
		var split = $.cookie(cookie_name).split(",");
		if (split.length == 0){
			var name  = $.cookie(cookie_name).substr(0, $.cookie(cookie_name).indexOf(':'));
			var value = $.cookie(cookie_name).substr($.cookie(cookie_name).indexOf(':')+1);
			if(value){
				$('input[name="'+name+'"]').val(value);
			}
		}else{
			for (var i = 0; i < split.length; i++) {
				var name  = split[i].substr(0, split[i].indexOf(':'));
				var value = split[i].substr(split[i].indexOf(':')+1);
				if(value){
					$('input[name="'+name+'"]').val(value);
				}
			}
		}
	}
	catch(err){
		$.cookie(cookie_name,'',{path: '/'});
	}
}
function appendValue(current,value){
	if(current == ''){
		current = value;
	}else{
		current = current + ',' + value;
	}
	return current;
}
function preLoadSearch(){
	var cookie_name = $('input[name="cookie"]').val();
	var loadpresets = $('input[name="loadpresets"]').val();
	
	if($('input[name="p_searchwords"]').val()){
		eraseCookie('/');
		generateCookie();
		postForm();
	}else{
		splitCookie();
	}
	
	var string = '?';
	$('input[type="hidden"]:not(.default)').each(function(){
		if(string == '?'){
			string = string + $(this).attr('name') + '=' + $(this).val();
		}else{
			string = string + '&' + $(this).attr('name') + '=' + $(this).val();
		}
	});
	
	if(loadpresets == 'Y'){
		if ($('input[name="p_searchpagetype"]').val() != 'lecturers'){
			postForm();
		}else{
			if ($('input[name="p_searchwords"]').val()){
				postForm();
			}
		}
	}
}

function searchPageCookie(page){
	$.cookie('uva-search-page',page,{path: '/'});
}